import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/admin/login.scss';

import { APIS, ROUTES, DEFAULT_COLOR } from 'constants/index';
import { post } from 'utils/networks';
import { isUserLoggedIn, setCookie, showErrorMessage } from 'utils';
import { TestIds } from 'utils/testing/testIds';

import Layout from 'components/shared/layout';
import PrimaryButton from 'components/shared/primaryButton.tsx';
import { toast } from 'react-toastify';
import PasswordField from 'components/shared/form/passwordField';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';

const { REACT_APP_API_URL } = process.env;

const { LOGIN_FORGOT_PASSWORD_BUTTON } = TestIds;

export default function Login() {
  const [loading, setLoading] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { dispatch } = useAccounts();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      email: emailRef.current?.value,
      password: passwordRef.current?.value
    };
    setLoading(true);
    const response = await post(APIS.admin.login, data);
    setLoading(false);

    if (response.ok) {
      setCookie('token', response.data.token, 8);
      setCookie('uid', response.data.uid, 8);
      setCookie('adminRole', response.data.role, 8);
      if (response.data.click_and_collect) {
        localStorage.setItem(
          'remote_open_enabled',
          response.data.click_and_collect.remote_open_enabled
        );
      }
      if (response.data.branding) {
        const brandingLogo = `${REACT_APP_API_URL}/${response.data.branding.logo}`;
        const brandingColor = response.data.branding.color;

        dispatch({ type: 'setColor', color: brandingColor });
        dispatch({ type: 'setLogo', logo: brandingLogo });

        localStorage.setItem('brandingColor', brandingColor);
        localStorage.setItem('brandingLogo', brandingLogo);
      }
      (event.target as HTMLFormElement).reset();
      navigate(ROUTES.admin.dashboard);
    }
  };

  useEffect(() => {
    localStorage.removeItem('brandingColor');
    localStorage.removeItem('brandingLogo');
    document.documentElement.style.setProperty('--branding-color', DEFAULT_COLOR);
  }, []);

  useEffect(() => {
    const aunthenticated = isUserLoggedIn();
    const accessDeniedAlert = localStorage.getItem('access_denied_alert');
    if (aunthenticated) {
      toast.info('You are already signed in.');
      navigate(ROUTES.admin.dashboard);
    } else {
      showErrorMessage(accessDeniedAlert as string);
      localStorage.removeItem('access_denied_alert');
    }
  }, [navigate]);

  return (
    <Layout>
      <form className="login-form" onSubmit={handleSubmit}>
        <label htmlFor="email"> Email </label>
        <input
          id="email"
          ref={emailRef}
          name="email"
          type={'email'}
          placeholder="Enter Email Address"
          required
        />
        <label htmlFor="password"> Password </label>
        <PasswordField
          id="password"
          name="password"
          placeholder="Enter Password"
          ref={passwordRef}
          required
        />
        <div className="remember-me-cont">
          <input type={'checkbox'} className="checkbox" />
          <span className="remember-me"> Remember me </span>
          <span
            data-testid={LOGIN_FORGOT_PASSWORD_BUTTON}
            className="forgot-password"
            onClick={() => navigate(ROUTES.admin.forgotPassword)}>
            Forgot password?
          </span>
        </div>
        <PrimaryButton loading={loading} buttonType="submit">
          Login
        </PrimaryButton>
      </form>
    </Layout>
  );
}
