import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/admin/createOrder.scss';
import ScanIcon from 'assets/images/new-scan-icon.png';

import CreateOrderForm from 'components/admin/createOrder/form';
import Layout from 'components/shared/layout';
import OcrTextExtractor from 'components/admin/createOrder/ocr';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';
import { APIS, ROUTES } from 'constants/index';
import { getUserData } from 'utils';

export default function CreateOrder() {
  const navigate = useNavigate();
  const [performScan, setPerformScan] = useState(false);
  const [orderNo, setOrderNo] = useState<string>();
  const [telePhoneNo, setTelePhoneNo] = useState<string>();
  const [name, setName] = useState<string>();
  const { logo } = useAccounts();

  const handleVideoShow = () => {
    setPerformScan(false);
  };

  const setCustomerName = (customerName: string) => {
    setName(customerName);
  };

  const setCustomerTelephoneNo = (customerTelephoneNo: string) => {
    setTelePhoneNo(customerTelephoneNo);
  };

  const setCustomerOrderNo = (customerOrderNo: string) => {
    setOrderNo(customerOrderNo);
  };

  const handleCancel = () => {
    setPerformScan(false);
    setOrderNo('');
    setName('');
    setTelePhoneNo('');
  };

  useEffect(() => {
    getUserData(APIS.admin.me);
  }, []);

  return (
    <Layout
      backIcon={true}
      navigateToHome={true}
      logoIcon={logo}
      onBackClick={() => navigate(ROUTES.admin.dashboard)}>
      {performScan ? (
        <OcrTextExtractor
          submitHandler={handleVideoShow}
          setCustomerName={setCustomerName}
          setCustomerTelephoneNo={setCustomerTelephoneNo}
          setCustomerOrderNo={setCustomerOrderNo}
          cancel={handleCancel}
        />
      ) : (
        <div className="create-order">
          <div className="heading-container">
            <div className="heading no-margin"> Create Order </div>
            <img
              className="scan_icon"
              style={{ width: '40px', height: '40px', cursor: 'pointer' }}
              src={ScanIcon}
              onClick={() => setPerformScan(true)}
            />
          </div>
          <CreateOrderForm
            orderReference={orderNo}
            customerFirstName={name?.split(' ')[0]}
            customerLastName={name?.split(' ')[1]}
            customerPhone={telePhoneNo}
          />
        </div>
      )}
    </Layout>
  );
}
