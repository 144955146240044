import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/admin/sidemenu.scss';

import PrimaryButton from 'components/shared/primaryButton.tsx';
import useBlurHandler from 'components/shared/hooks/useBlurHandler';
import { useAccounts } from 'components/admin/dashboard/accountsContextLayout';
import type { IAccount } from 'components/admin/dashboard/accountsContextLayout';

import CloseIcon from 'assets/images/close-icon.svg';

import { APIS, ROUTES, DEFAULT_COLOR } from 'constants/index';
import { clearUserData } from 'utils';
import { TestIds } from 'utils/testing/testIds';
import { get, post } from 'utils/networks';

interface Props {
  showMenu: boolean;
  onClose: () => void;
}

const { SIDE_MENU_DASHBOARD, SIDE_MENU_CHANGE_PASSWORD, SIDE_MENU_LOGOUT } = TestIds;

export default function Sidemenu({ showMenu, onClose }: Props) {
  const { ref } = useBlurHandler(showMenu, onClose);
  const [activeScreen, setActiveScreen] = useState<string>(
    window.location.pathname.split('/admin')[1] ?? ''
  );

  const navigate = useNavigate();
  const { accounts, dispatch } = useAccounts();
  const [activeAccount, setActiveAccount] = useState<IAccount>();

  const getAccounts = useCallback(async () => {
    const response = await get(APIS.admin.accounts);
    if (response.ok) {
      dispatch({ type: 'setData', data: response.data.accounts });
      setActiveAccount(
        response.data.accounts.filter((account: { active: any }) => account.active)[0]
      );
    }
  }, [dispatch]);

  const handleLogOut = () => {
    setActiveScreen('/logout');
    clearSession();
  };

  const clearSession = async () => {
    const response = await post(APIS.admin.logout);
    if (response.ok) {
      clearUserData();
      dispatch({ type: 'setData', data: [] });
      dispatch({ type: 'setColor', color: DEFAULT_COLOR });
      dispatch({ type: 'setLogo', logo: '' });
      navigate(ROUTES.admin.login);
    }
  };

  useEffect(() => {
    if (accounts.length == 0) {
      getAccounts();
    } else {
      setActiveAccount(accounts.filter((account: { active: any }) => account.active)[0]);
    }
  }, [accounts, accounts.length, getAccounts]);

  return (
    <div className="sidemenu-cont">
      <div ref={ref} className="sidemenu">
        <div className="header">
          <span className="menu-text"> Menu </span>
          <img src={CloseIcon} className="close-icon" alt="close-menu" onClick={onClose} />
        </div>

        {accounts.length > 1 && (
          <div className="switch-account-section">
            <div className="account-name"> {activeAccount?.name} </div>
            <PrimaryButton onClick={() => navigate(ROUTES.admin.switchAccount)}>
              Switch account
            </PrimaryButton>
          </div>
        )}

        {accounts.length > 1 && <hr className="border-line" />}

        <div
          data-testid={SIDE_MENU_DASHBOARD}
          className={['screen', activeScreen === '' ? 'active-screen' : ''].join(' ')}
          onClick={() => {
            if (activeScreen !== '') {
              setActiveScreen('');
              navigate(ROUTES.admin.dashboard);
              return;
            }
            onClose();
          }}>
          Dashboard
        </div>
        <div
          data-testid={SIDE_MENU_CHANGE_PASSWORD}
          className={['screen', activeScreen === '/password/change' ? 'active-screen' : ''].join(
            ' '
          )}
          onClick={() => navigate(ROUTES.admin.changePassword)}>
          Change Password
        </div>
        <div
          data-testid={SIDE_MENU_LOGOUT}
          className={['screen', activeScreen === '/logout' ? 'active-screen' : ''].join(' ')}
          onClick={handleLogOut}>
          Log Out
        </div>
      </div>
    </div>
  );
}
