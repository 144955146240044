const { REACT_APP_API_URL } = process.env;
const apiBaseUrl = `${REACT_APP_API_URL}/api/v1/click_and_collect`;

// APIs
export const APIS = {
  admin: {
    login: `${REACT_APP_API_URL}/api/v1/sign_in`,
    logout: `${REACT_APP_API_URL}/api/v1/sign_out`,
    changePassword: `${REACT_APP_API_URL}/api/v1/passwords`,
    forgotPassword: `${REACT_APP_API_URL}/api/v1/users/password`,
    me: `${REACT_APP_API_URL}/api/v1/me`,
    accounts: `${apiBaseUrl}/accounts`,
    orders: `${apiBaseUrl}/orders`,
    order: (id: string) => `${apiBaseUrl}/orders/${id}`,
    orderStatuses: `${apiBaseUrl}/order_statuses`,
    lockers: `${apiBaseUrl}/lockers`,
    allocateLockerToOrder: (id: string) => `${apiBaseUrl}/orders/${id}/allocate`,
    unlockLocker: (id: string) => `${apiBaseUrl}/orders/${id}/unlock`,
    lockerStatus: (id: string) => `${apiBaseUrl}/orders/${id}/locker_status`,
    lockerTags: (id: string) => `${apiBaseUrl}/lockers/tags?order_id=${id}`,
    verifyOrder: (id: string) => `${apiBaseUrl}/orders/${id}/verify`,
    orderLogs: (id: string) => `${apiBaseUrl}/orders/${id}/logs`,
    cancelOrder: (id: string) => `${apiBaseUrl}/orders/${id}/cancel`,
    reactivateOrder: (id: string) => `${apiBaseUrl}/orders/${id}/reactivate`,
    clearOutOrder: (id: string) => `${apiBaseUrl}/orders/${id}/clearout`,
    autoAllocateLockerToOrder: (id: string) => `${apiBaseUrl}/orders/${id}/auto_allocate`,
    statuses: (id: string) => `${apiBaseUrl}/orders/${id}/statuses`,
    revertOrderLocker: (id: string) => `${apiBaseUrl}/orders/${id}/revert`,
    ocrUpload: `${REACT_APP_API_URL}/api/v1/ocr/upload`,
    lockerTagsWithoutOrderId: `${apiBaseUrl}/lockers/tags`,
    lockerForceOpen: (id: string) => `${apiBaseUrl}/lockers/${id}/open`,
    lockerForceOpenStatus: (id: string) => `${apiBaseUrl}/lockers/${id}/open_status`,
    locations: `${apiBaseUrl}/locations`,
    loadSession: (id: string) => `${apiBaseUrl}/orders/${id}/load_session`
  },
  customer: {
    order: (token: string) => `${apiBaseUrl}/customer_orders/${token}`,
    requestVerification: (token: string) =>
      `${apiBaseUrl}/customer_orders/${token}/request_verification`,
    unlockLocker: (token: string) => `${apiBaseUrl}/customer_orders/${token}/unlock`,
    lockerStatus: (token: string) => `${apiBaseUrl}/customer_orders/${token}/locker_status`,
    contactUsSettings: (token: string) => `${apiBaseUrl}/contact_us_settings/${token}`,
    submitFeedback: (token: string, id: string) =>
      `${apiBaseUrl}/customer_orders/${token}/feedback?feedback=${id}`
  }
};

// Routes
export const ROUTES = {
  customer: {
    storeName: '/customer',
    storeNameScan: '/customer/scan',
    ageVerification: '/customer/verification',
    orderComplete: '/customer/order/complete',
    orderFeedback: '/customer/order/feedback',
    orderCancelled: '/customer/order/cancelled',
    orderCollected: '/customer/order/collected',
    scanQRCode: '/customer/scanQR',
    openLockerCustomer: '/customer/locker/open',
    customerErrorPage: '/customer/error',
    contactUs: '/customer/contactUs',
    collectionWindowTimeOutPage: '/customer/timeOut',
    helpVideo: '/customer/tutorial'
  },
  admin: {
    dashboard: '/admin',
    login: '/',
    changePassword: '/admin/password/change',
    switchAccount: '/admin/account/switch',
    forgotPassword: '/admin/password/forgot',
    updatePassword: '/admin/password/update',
    viewOrders: '/admin/orders',
    loadOrders: '/admin/orders/load',
    clearOutOrder: '/admin/orders/clear',
    clearOutOrderList: '/admin/orders/clear/list',
    individualOrder: '/admin/order',
    createOrder: '/admin/order/create',
    chooseLocker: '/admin/locker/select',
    selectLocker: '/admin/locker/size',
    autoAllocateLocker: '/admin/locker/auto_allocate',
    openLockerAdmin: '/admin/locker/open',
    ageVerificationAdmin: '/admin/verification',
    cancelVerifiedOrder: '/admin/verification/cancel',
    adminErrorPage: '/admin/error',
    lockers: '/admin/lockers',
    forceOpenLocker: '/admin/locker/force_open'
  }
};

export const ORDER_STATUSES = {
  pending: 'pending',
  loading: 'loading',
  active: 'ready_for_collection',
  require_verification: 'require_verification',
  verified: 'verified',
  collecting: 'collecting',
  completed: 'collected',
  clear_out: 'clear_out',
  clearing_out: 'clearing_out',
  cancelled: 'cancelled',
  new_pending: 'ready_to_load'
};

export const DEFAULT_COLOR = '#00a4df';
