import { toast, Zoom } from 'react-toastify';
import { ResponseType, ErrorType, get } from 'utils/networks';

export const isUserLoggedIn = () =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith('token='))
    ?.split('=')[1]
    ? true
    : false;

export const setCookie = (name: string, value: string, hours: number) => {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + '; ' + expires + '; path=/';
};

export const uid = () =>
  Date.now().toString(36) +
  Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36);

export const getAuthToken = () =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith('token='))
    ?.split('=')[1];

export const getUserId = () =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith('uid='))
    ?.split('=')[1];

export const getAdminRole = () =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith('adminRole='))
    ?.split('=')[1];

export const clearAuthToken = () => {
  document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const clearUidAndBranding = () => {
  document.cookie = `uid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  document.cookie = `adminRole=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  localStorage.removeItem('brandingColor');
  localStorage.removeItem('brandingLogo');
  localStorage.removeItem('remote_open_enabled');
};

export const clearUserData = () => {
  clearAuthToken();
  clearUidAndBranding();
};

export const getUserData = async (endpoint: string) => {
  try {
    await get(endpoint);
  } catch (error) {
    console.error('Failed get User Data', error);
  }
};

export const setCustomerTokenWithExpiry = (token: string, index: string) => {
  const ttlInHours = 12;
  const expiryTimestamp = Date.now() + ttlInHours * 60 * 60 * 1000;
  const tokenObject = { token, expiry: expiryTimestamp };
  localStorage.setItem(`customerToken_${index}`, JSON.stringify(tokenObject));
};

export const getCustomerToken = (index: string) => {
  const tokenData = localStorage.getItem(`customerToken_${index}`) ?? '';

  if (!tokenData) {
    console.warn(`No token found for customerToken_${index}`);
    return null;
  }

  try {
    const { token } = JSON.parse(tokenData);
    return token;
  } catch (error) {
    console.error('Failed to parse token data:', error);
    return null;
  }
};

export const getCandCRemoteOpenEnabled = (): string | boolean =>
  localStorage.getItem('remote_open_enabled') ?? false;

export const getStoredItem = (key: string) => {
  try {
    return localStorage.getItem(key) ?? '';
  } catch (e) {
    console.error(`Error getting item from localStorage: ${key}`, e);
    return '';
  }
};

export const handleApiError = (response: any) => {
  let error: string | undefined;

  if (response?.data?.errors) {
    error = response.data.errors;
  }

  if (response?.data?.error) {
    error = response.data.error;
  }

  if (error?.includes('Access Denied')) {
    localStorage.setItem('access_denied_alert', error);
    clearUserData();
    window.location.reload();
  } else {
    showErrorMessage(error || 'Something went wrong!');
  }
};

export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const showSuccessMessage = (message: string) =>
  toast.success(message, {
    position: 'top-center',
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Zoom,
    progress: undefined,
    theme: 'colored'
  });

export const showErrorMessage = (error: string) =>
  toast.error(error, {
    position: 'top-center',
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Zoom,
    progress: undefined,
    theme: 'colored'
  });

export const convertTime = (time: string): string => {
  if (Number(time.substring(0, 2)) > 12) {
    const newTime = String((Number(time.replace(':', '.')) % 12).toPrecision(3));

    return minutesCheck(newTime).replace('.', ':').concat('pm');
  }

  return minutesCheck(time.replace(':', '.')).replace('.', ':').concat('am');
};

const minutesCheck = (time: string): string => {
  const parts = time.split('.');

  return parts[1] === '00' ? parts[0] : time;
};

export const truncateText = (text: string, length: number) => {
  if (text.length <= length) {
    return text;
  }
  return text.substring(0, length) + '...';
};

export function isResponseType(response: ResponseType | ErrorType): response is ResponseType {
  return (response as ResponseType).headers !== undefined;
}
